import React, {useEffect, useState} from 'react';
import api from '../../../services/api';
import { Form, Input, Select } from '@rocketseat/unform';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
    format,
  } from 'date-fns';
import pt from 'date-fns/locale/pt';

import * as Yup from 'yup';

import { Container, FormDiv, TableDiv } from './styles';
import { Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


const schema = Yup.object().shape({
  city: Yup.string().required('O nome da cidade é obrigatório'),
  place: Yup.string().required('O endereço é obrigatório'),
  date: Yup.date().typeError('Digite uma data valida').required('A data é obrigatória'),
  time: Yup.string().required('O horário é obrigatório'),
  lanes: Yup.string().required('A quantidade de raias é obrigatório'),
});

function Competitions() {
  const [competitions, setCompetitions] = useState([]);

  useEffect(() => {
    async function loadCompetitions() {
      const response = await api.get('competitions');

      const data = response.data;

      setCompetitions(data);
    }

    loadCompetitions();
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function handleSubmit(data) {
      if (!updating) {
        try{
          const strokesResponse = await api.get('strokes');
          const strokes = strokesResponse.data;
          let strokesArray = [];
          for (let stroke in strokes) {
              strokesArray.push(stroke.id);
          }
          const strokeList = {strokes_list: strokesArray}
  
          await api.post('competitions', {...data, ...strokeList});
        }
        catch(e) {
          toast.error('Verifique se a competição já existe');
        }
      }
      else {
        try{  
          await api.put(`competitiondata/${profile.id}`, {
            city: data.city,
            place: data.place,
            date: data.date,
            time: data.time,
            lanes: data.lanes
          });

          toast.success('Competição alterada com sucesso');
          setProfile({});
          setUpdating(!updating);
        }
        catch(e) {
          toast.error('Verifique se a competição já existe');
        }
      }


    const response = await api.get('competitions');

    setCompetitions(response.data);

  }

  const [modal, setModal] = useState(false);
  const [competitionId, setCompetitionId] = useState();
  const [profile, setProfile] = useState();
  const [updating, setUpdating] = useState(false);

  const toggle = () => setModal(!modal);

  const toggleId = (id) => {
    setModal(!modal);
    setCompetitionId(id);
  }

  async function updateCompetition(category) {
    setProfile(category);
    try{
        await api.put(`competitions/${category.id}`);
        toast.success('Status da competição alterada com sucesso');
        setProfile({});
        const response = await api.get('competitions');

        setCompetitions(response.data);
      }
      catch(e) {
        toast.error('Atualizando aq');
      }
  }

  async function deleteCompetition() {
    try {
      await api.delete(`competitions/${competitionId}`);
      toast.success('Nado deletado com sucesso');
    }
    catch(e) {
      toast.error('Erro ao deletar a competição');
    }

    const response = await api.get('competitions');

    setCompetitions(response.data);
    setModal(!modal);
  } 

  function toggleUpdate(category) {
    setProfile(category);
    setUpdating(!updating);
  }

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }
  
  function formatDate(date) {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join('/');
  }

  return (
    <Container>
      <FormDiv>
      <h1>Competição</h1>
      <Form schema={schema} initialData={profile} onSubmit={handleSubmit}>
        <Input name="city" type="text" placeholder="Nome da Cidade" />
        <Input name="place" type="text" placeholder="Endereço" />
        <Input name="date" type="date" placeholder="Data" />
        <Input name="time" type="text" placeholder="Horário" />
        <Input name="lanes" type="number" min={0} placeholder="Número de raias" />
        <button type="submit">Salvar</button>
      </Form>
      </FormDiv>
      <TableDiv>
        <Table>
          <thead>
            <tr>
              <th>Cidade</th>
              <th>Endereço</th>
              <th>Data</th>
              <th>Horário</th>
              <th>Raias</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
          {
            competitions.map(competition => (
              <tr key={competition.id}>
                <td>{competition.city}</td>
                <td>{competition.place}</td>
                <td>{format(new Date(moment(competition.date).format()), "d 'de' MMMM", { locale: pt })}</td>
                <td>{competition.time}</td>
                <td>{competition.lanes}</td>
                <td>
                    <button onClick={() => toggleUpdate(competition)}>{updating && competition.id == profile.id ? 'Cancelar' : 'Editar'}</button>
                    <button onClick={() => updateCompetition(competition)}>{competition.opened == false ? 'Abrir' : 'Fechar'}</button>
                </td>
              </tr>
            ))
          }
          </tbody>
        </Table>
      </TableDiv>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Deletar Categoria</ModalHeader>
        <ModalBody>
          Deseja Realmente deletar a categoria ?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => deleteCompetition()}>Deletar</Button>{' '}
          <Button color="secondary" onClick={toggle}>Cancelar</Button>
        </ModalFooter>
      </Modal>

    </Container>
  );
}

export default Competitions;