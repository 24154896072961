import React, {useEffect, useState} from 'react';
import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";
import api from '../../../services/api';
import { Form, Input, Select } from '@rocketseat/unform';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
    format,
  } from 'date-fns';
import pt from 'date-fns/locale/pt';
import * as Yup from 'yup';

import logo from '../../../assets/logo.png'

import history from '../../../services/history';
import { Container, FormDiv, TableDiv, PrintHeader } from './styles';
import { Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function PrintPontuation() {
  let query = useQuery();

  const [races, setRaces] = useState([]);
  const [relaysRaces, setRelaysRaces] = useState([]);
  const [competition, setCompetition] = useState({});

  useEffect(() => {
    async function loadOpenedCompetitions() {
      const strokesResponse = await api.get('strokes');
      const categoriesResponse = await api.get('categories');
      const relaysResponse = await api.get('strokes/relays');
      const strokes = strokesResponse.data;
      const relays = relaysResponse.data;
      const categories = categoriesResponse.data;

      const competitionReponse = await api.get(`competition/${query.get('id')}`)
      const competitionData = competitionReponse.data;

      let balizamento = [];
      let revezamentos = [];

      const response = await api.get(`/pontuation/races/${query.get('id')}`);
      const races = response.data;


      for (const stroke of strokes) {
        for (const categorie of categories) {
          let athletesArray = []
          for (const race of races) {     
            if (race.stroke_id == stroke.id && race.category_id == categorie.id) {
              athletesArray.push(race);
              if (balizamento.some(e => (e.stroke == `${stroke.name} ${stroke.sex}`) && (e.categoriy == categorie.name))) {
              }
              else {
                balizamento.push({
                  stroke: `${stroke.name} ${stroke.sex}`,
                  categoriy: categorie.name,
                  athletes: athletesArray,
                }); 
              }              
            }    
          }   
        }
      }

      

      for (const relay of relays) {
        const response = await api.get(`pontuations/relays/${query.get('id')}/${relay.id}`);
        const data = response.data;
        revezamentos.push({
          stroke: `${relay.name} ${relay.sex}`,
          teams: data,
        })
      }

      setRaces(balizamento);
      setRelaysRaces(revezamentos);
      setCompetition(competitionData);
    }

    loadOpenedCompetitions();
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps


  return (
    <Container>
      <TableDiv>
        <PrintHeader>
          <img src={logo}></img>
            <div>
            <h2>Cidade: {competition.city}</h2>
            <h2>Data: {format(new Date(moment(competition.date).format()), "d 'de' MMMM", { locale: pt })}</h2>
          </div>
        </PrintHeader>
        <h1>Resultados por Categoria</h1>
        {
        races.map((race, index) => 
          (
            <>
            {
                race.athletes.length > 0 ?
                (
                    <div className='race' key={index}>
                    <h1>{`${race.stroke} - ${race.categoriy}`}</h1>
                    <Table  key={index} className="table table-striped">
                        <thead>
                        <tr>
                            <th>Nado</th>
                            <th>Nome</th>
                            <th>Categoria</th>
                            <th>Equipe</th>
                            <th>Tempo</th>
                            <th>Pontuação</th>
                        </tr>
                        </thead>
                        <tbody>
                        {race.athletes.map(athlete => (
                            <tr key={athlete.id}>
                              <td>{race.stroke}</td>
                              <td>{athlete.athlete?.name}</td>
                              <td>{athlete.category?.name}</td>
                              <td>{athlete.athlete?.team.name}</td>
                              <td>{athlete.time == '99:99:99' ? 'DESCLASSIFICADO' : athlete.time}</td>
                              <td>
                              {
                              (athlete.category.agemin > 10 || race.stroke.includes('25')) 
                              && (race.stroke.includes('50') || race.stroke.includes('25') || (race.stroke.includes('Medley') && race.stroke.includes('100')) || (race.stroke.includes('medley') && race.stroke.includes('100'))) 
                              ? 
                              'NÃO PONTUA' 
                              : 
                              athlete.pontuation
                              }
                              </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    </div>
                  )
                  :
                  (
                    <></>
                  )
              }
              </>
              
          )
        )
        }
        {/* {
        relaysRaces.map((relay,index) => (
          <div className='race' key={index}>
            <Table key={index} className="table table-striped">
                <thead>
                <tr>
                    <th>Nado</th>
                    <th>Nome da Equipe</th>
                    <th>Tempo</th>
                </tr>
                </thead>
                <tbody>
                {relay.teams.map(team => (
                    <tr key={team.id}>
                      <td>{relay.stroke}</td>
                      <td>{team.team.name}</td>
                      <td>{team.time == '99:99:99' ? 'DESCLASSIFICADO' : team.time}</td>
                    </tr>
                ))}
                </tbody>
            </Table>
          </div>
        ))
        } */}
      </TableDiv>

    </Container>
  );
}

export default PrintPontuation;
