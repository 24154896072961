import React, {useEffect, useState} from 'react';
import api from '../../../services/api';
import { Form, Input, Select } from '@rocketseat/unform';
import { toast } from 'react-toastify';

import * as Yup from 'yup';

import { Container, FormDiv, TableDiv } from './styles';
import { Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


const schema = Yup.object().shape({
  name: Yup.string().required('O nome da categoria é obrigatório'),
  agemin: Yup.number().typeError('Digite uma idade válida').required('O valor é obrigatório'),
  agemax: Yup.number().typeError('Digite uma idade válida').required('O valor é obrigatório'),
});

function Categories() {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    async function loadCategories() {
      const response = await api.get('categories');

      const data = response.data;

      setCategories(data);
    }

    loadCategories();
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function handleSubmit(data) {
    if (!updating) {
      try{
        await api.post('categories', data);
      }
      catch(e) {
        toast.error('Verifique se a categoria já existe');
      }
    }
    else {
      try{
        await api.put(`categories/${profile.id}`, {
          name: data.name,
          agemin: data.agemin,
          agemax: data.agemax,
        });
        toast.success('Categoria alterada com sucesso');
        setProfile({});
        setUpdating(!updating);
      }
      catch(e) {
        toast.error('Verifique se a categoria já existe');
      }
    }


    const response = await api.get('categories');

    setCategories(response.data);

  }

  const [modal, setModal] = useState(false);
  const [categoryId, setCategoryId] = useState();
  const [profile, setProfile] = useState();
  const [updating, setUpdating] = useState(false);

  const toggle = () => setModal(!modal);

  const toggleId = (id) => {
    setModal(!modal);
    setCategoryId(id);
  }

  async function deleteProduct() {
    try {
      await api.delete(`categories/${categoryId}`);
      toast.success('Categoria deletada com sucesso');
    }
    catch(e) {
      toast.error('Erro ao deletar a categoria');
    }

    const response = await api.get('categories');

    setCategories(response.data);
    setModal(!modal);
  }

  function toggleUpdate(category) {
    setProfile(category);
    setUpdating(!updating);
  }

  return (
    <Container>
      <FormDiv>
      <h1>Categoria</h1>
      <Form schema={schema} initialData={profile} onSubmit={handleSubmit}>
        <Input name="name" type="text" placeholder="Nome da Categoria" />
        <Input name="agemin" type="text" placeholder="Idade minima" />
        <Input name="agemax" type="text" placeholder="Idade máxima" />
        <button type="submit">Salvar</button>
      </Form>
      </FormDiv>
      <TableDiv>
        <Table>
          <thead>
            <tr>
              <th>Categoria</th>
              <th>Idade Mínima</th>
              <th>Idade Máxima</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
          {
            categories.map(category => (
              <tr key={category.id}>
                <td>{category.name}</td>
                <td>{category.agemin}</td>
                <td>{category.agemax}</td>
                <td>
                  <button onClick={() => toggleUpdate(category)}>{updating && category.id == profile.id ? 'Cancelar' : 'Editar'}</button>
                </td>
              </tr>
            ))
          }
          </tbody>
        </Table>
      </TableDiv>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Deletar Categoria</ModalHeader>
        <ModalBody>
          Deseja Realmente deletar a categoria ?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => deleteProduct()}>Deletar</Button>{' '}
          <Button color="secondary" onClick={toggle}>Cancelar</Button>
        </ModalFooter>
      </Modal>

    </Container>
  );
}

export default Categories;