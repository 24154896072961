import React, {useEffect, useState} from 'react';
import moment from 'moment';
import {
  format,
} from 'date-fns';
import pt from 'date-fns/locale/pt';

import api from '../../services/api';
import { Wrapper, Container, HomeBanner, Etapas} from './styles';

function Competitions() {

  const [competitions, setCompetitions] = useState([]);

  useEffect(() => {
    async function loadStrokes() {
      const response = await api.get('competitions/previous');
      const data = response.data;

      setCompetitions(data);
    }

    loadStrokes();
  }, []);

  return (
    <Wrapper>
      <HomeBanner>
        <h1>SEJA MUITO BEM-VINDO À <span>LPN</span></h1>
        <span>Liga Paulista de Natação</span>
      </HomeBanner>
      <Container>
        <Etapas>
          <h2>Etapas Anteriores</h2>
          <div>
          {
            competitions.map(competition => (
              <div className="card text-white bg-primary mb-3" key={competition.id}>
                <div className="card-header">Etapa {competition.city}</div>
                <div className="card-body">
                  <h5 className="card-title">Endereço: {competition.place}</h5>
                  <p className="card-text">Competição realizada no dia: {format(new Date(moment(competition.date).format()), "d 'de' MMMM yyyy", { locale: pt })} as: {competition.time}</p>
                  <a target="_blank" className="card-text pontuation-link" href={`/races?name=print-races&id=${competition.id}`}>Balizamento</a>
                  <a target="_blank" className="card-text pontuation-link" href={`/pontuationcompetition?name=print-pontuation&id=${competition.id}`}>Resultado por categoria</a>
                  <a target="_blank" className="card-text pontuation-link" href={`/pontuationabsolute?name=print-pontuation&id=${competition.id}`}>Resultado Absoluto/Revezamento</a>
                  
                  
                </div>
              </div>
            ))
          }
          </div>
        </Etapas>
      </Container>
    </Wrapper>
  )
}

export default Competitions
