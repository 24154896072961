import React, {useEffect, useState} from 'react';
import api from '../../../services/api';
import { Form, Input, Select } from '@rocketseat/unform';
import { toast } from 'react-toastify';

import * as Yup from 'yup';

import { Container, FormDiv, TableDiv } from './styles';
import { Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


const schema = Yup.object().shape({
  login: Yup.string().required('O login é obrigatório'),
  password: Yup.string().required('a senha é obrigatória'),
  name: Yup.string().required('O nome da equipe é obrigatório'),
  coach: Yup.string().required('O nome do técnico é obrigatório'),
  city: Yup.string().required('O nome da cidade é obrigatório'),
  cep: Yup.string().required('O cep da equipe é obrigatório'),
  street: Yup.string().required('A rua da equipe é obrigatório'),
  number: Yup.number().typeError('Digite um número válido').required('o número da rua da equipe é obrigatório'),
  email: Yup.string().required('o email da equipe é obrigatório'),
  cellphone: Yup.string().required('o celular da equipe é obrigatório'),
});

const schemaEdit = Yup.object().shape({
  login: Yup.string().required('O login é obrigatório'),
  password: Yup.string().required('A senha é obrigatória'),
  confirmPassword: Yup.string().required('A confirmação de senha é obrigatória'),
});

function Teams() {
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    async function loadTeams() {
      const response = await api.get('teams');

      const data = response.data;

      setTeams(data);
    }

    loadTeams();
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function handleSubmit(data) {
    if (!updating) {
      try{
        await api.post('teams', data);
      }
      catch(e) {
        toast.error('Verifique se a equipe já existe');
      }
    }
    else {
      try{
        await api.put(`teams/${profile.id}`, {
          login: data.login,
          password: data.password,
          confirmPassword: data.confirmPassword,
        });
        toast.success('Equipe alterada com sucesso');
        setProfile({});
        setUpdating(!updating);
      }
      catch(e) {
        toast.error('Verifique se a equipe já existe');
      }
    }


    const response = await api.get('teams');

    setTeams(response.data);

  }

  const [modal, setModal] = useState(false);
  const [teamId, setTeamId] = useState();
  const [profile, setProfile] = useState();
  const [updating, setUpdating] = useState(false);

  const toggle = () => setModal(!modal);

  const toggleId = (id) => {
    setModal(!modal);
    setTeamId(id);
  }

  async function deleteProduct() {
    try {
      await api.delete(`teams/${teamId}`);
      toast.success('Equipe deletada com sucesso');
    }
    catch(e) {
      toast.error('Erro ao deletar a equipe');
    }

    const response = await api.get('teams');

    setTeams(response.data);
    setModal(!modal);
  }

  function toggleUpdate(category) {
    setProfile(category);
    setUpdating(!updating);
  }

  return (
    <Container>
        {
            updating ?
            (
                <FormDiv>
                <h1>Equipe</h1>
                <Form schema={schemaEdit} initialData={profile} onSubmit={handleSubmit}>
                    <Input name="login" type="text" placeholder="Login da equipe" />
                    <Input name="password" type="password" placeholder="Senha da equipe" />
                    <Input name="confirmPassword" type="password" placeholder="Confirme a senha" />
                    <button type="submit">Salvar</button>
                </Form>
                </FormDiv>
            )
            :
            (
                <FormDiv>
                <h1>Equipe</h1>
                <Form schema={schema} initialData={profile} onSubmit={handleSubmit}>
                    <Input name="login" type="text" placeholder="Login da equipe" />
                    <Input name="password" type="password" placeholder="Senha da equipe" />
                    <Input name="name" type="text" placeholder="nome da equipe" />
                    <Input name="coach" type="text" placeholder="nome do técnico" />
                    <Input name="city" type="text" placeholder="nome da cidade" />
                    <Input name="cep" type="text" placeholder="cep da equipe" />
                    <Input name="street" type="text" placeholder="nome da rua" />
                    <Input name="number" type="number" min={0} placeholder="número da rua" />
                    <Input name="email" type="email" placeholder="email da equipe" />
                    <Input name="cellphone" type="text" placeholder="celular da equipe" />
                    <button type="submit">Salvar</button>
                </Form>
                </FormDiv>
            )
        }   
      <TableDiv>
        <Table>
          <thead>
            <tr>
              <th>Login</th>
              <th>Nome</th>
              <th>Técnico</th>
              <th>Cidade</th>
              <th>CEP</th>
              <th>Rua</th>
              <th>Número</th>
              <th>Email</th>
              <th>Celular</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
          {
            teams.map(team => (
              <tr key={team.id}>
                <td>{team.login}</td>
                <td>{team.name}</td>
                <td>{team.coach}</td>
                <td>{team.city}</td>
                <td>{team.cep}</td>
                <td>{team.street}</td>
                <td>{team.number}</td>
                <td>{team.email}</td>
                <td>{team.cellphone}</td>
                <td>
                    <button onClick={() => toggleUpdate(team)}>{updating && team.id == profile.id ? 'Cancelar' : 'Editar'}</button>
                    <button onClick={() => toggleId(team.id)}>Deletar</button>
                </td>
              </tr>
            ))
          }
          </tbody>
        </Table>
      </TableDiv>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Deletar Equipe</ModalHeader>
        <ModalBody>
          Deseja Realmente deletar a equipe ?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => deleteProduct()}>Deletar</Button>{' '}
          <Button color="secondary" onClick={toggle}>Cancelar</Button>
        </ModalFooter>
      </Modal>

    </Container>
  );
}

export default Teams;