import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import Default from '../pages/Default';
import SignUp from '../pages/SignUp';
import Competitions from '../pages/Competitions'

import Dashboard from '../pages/Dashboard';
import Races from '../pages/Print/Races';
import Papers from '../pages/Print/Papers';
import Reports from '../pages/Print/Reports';
import PontuationCompetition from '../pages/Print/PontuationCompetition';
import PontuationCompetitionAbsolute from '../pages/Print/PontuationCompetitionAbsolute';
import RankingAbsolute from '../pages/Print/RankingAbsolute';
import RankingCategories from '../pages/Print/RankingCategories';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Default} />
      <Route path="/login" component={SignUp} />
      <Route path="/previouscompetitions" component={Competitions} />
      <Route path="/pontuationcompetition" component={PontuationCompetition} />
      <Route path="/pontuationabsolute" component={PontuationCompetitionAbsolute} />
      <Route path="/rankingabsolute" component={RankingAbsolute} />
      <Route path="/rankingcategory" component={RankingCategories} />
      <Route path="/races" component={Races} />

      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route path="/papers" component={Papers} isPrivate />
      <Route path="/report" component={Reports} isPrivate />
      <Route path="/racesreferee" component={Races} isPrivate/>
    </Switch>
  );
}
