export function signInRequest(login, password) {
  return {
    type: '@auth/SIGN_IN_REQUEST',
    payload: { login, password },
  };
}

export function signInSuccess(token, user) {
  return {
    type: '@auth/SIGN_IN_SUCCESS',
    payload: { token, user },
  };
}

export function signUpRequest(
  name, 
  coach, 
  city, 
  cep, 
  street, 
  number, 
  email, 
  cellphone, 
  login, 
  password,
  ) {
  return {
    type: '@auth/SIGN_UP_REQUEST',
    payload: { 
      name, 
      coach, 
      city, 
      cep, 
      street, 
      number, 
      email, 
      cellphone, 
      login, 
      password,
    },
  };
}

export function signFailure() {
  return {
    type: '@auth/SIGN_FAILURE',
  };
}

export function signOut() {
  return {
    type: '@auth/SIGN_OUT',
  };
}