import React, {useEffect, useState} from 'react';
import api from '../../../services/api';
import { Form, Input, Select } from '@rocketseat/unform';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
    format,
  } from 'date-fns';
import pt from 'date-fns/locale/pt';
import * as Yup from 'yup';

import history from '../../../services/history';
import { Container, FormDiv, TableDiv } from './styles';
import { Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

function OpenedCompetitions() {
  const [openedCOmpetitions, setOpenedCompetitions] = useState([]);

  useEffect(() => {
    async function loadOpenedCompetitions() {
      const response = await api.get('competitions/opened');

      const data = response.data;

      setOpenedCompetitions(data);
    }

    loadOpenedCompetitions();
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps

  const [modal, setModal] = useState(false);
  const [openedCompetitionId, setOpenedCompetitionId] = useState();
  const [profile, setProfile] = useState();
  const [updating, setUpdating] = useState(false);

  const toggle = () => setModal(!modal);

  const toggleId = (id) => {
    setModal(!modal);
    setOpenedCompetitionId(id);
  }

  function toggleUpdate(category) {
    setProfile(category);
    setUpdating(!updating);
  }

  function signAthletes(competition) {
    history.push(`/dashboard?name=sign-athletes&id=${competition.id}`);
  }

  function listAthletes(competition) {
    history.push(`/dashboard?name=list-athletes&id=${competition.id}`);
  }

  return (
    <Container>
      <TableDiv>
        <Table>
            <thead>
            <tr>
                <th>Cidade</th>
                <th>Endereço</th>
                <th>Data</th>
                <th>Horário</th>
                <th>Raias</th>
                <th>Ações</th>
            </tr>
            </thead>
            <tbody>
            {
            openedCOmpetitions.map((openedCompetition,index) => (
              <tr key={index}>
                <td>{openedCompetition.city}</td>
                <td>{openedCompetition.place}</td>
                <td>{format(new Date(moment(openedCompetition.date).format()), "d 'de' MMMM", { locale: pt })}</td>
                <td>{openedCompetition.time}</td>
                <td>{openedCompetition.lanes}</td>
                <td>
                    <button onClick={() => signAthletes(openedCompetition)}>Cadastrar Atletas</button>
                    <button onClick={() => listAthletes(openedCompetition)}>Listar Atletas</button>
                </td>
              </tr>
            ))
          }
            </tbody>
        </Table>
      </TableDiv>

    </Container>
  );
}

export default OpenedCompetitions;