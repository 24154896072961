import React from 'react';
import PropTypes from 'prop-types';

export default function PrintLayout({ children }) {
  return (
    <>
        <div className='children'>{children}</div>
    </>
  );
}

PrintLayout.propTypes = {
  children: PropTypes.element.isRequired,
};