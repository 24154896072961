import React, {useEffect, useState} from 'react';
import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";
import api from '../../../services/api';
import { Form, Input, Select } from '@rocketseat/unform';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
    format,
  } from 'date-fns';
import pt from 'date-fns/locale/pt';
import * as Yup from 'yup';

import logo from '../../../assets/logo.png'

import history from '../../../services/history';
import { Container, FormDiv, TableDiv, PrintHeader } from './styles';
import { Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

function useQuery() {
  //const { search } = useLocation();

  //return React.useMemo(() => new URLSearchParams(search), [search]);
}

function RankingAbsolute() {
  //let query = useQuery();

  const [athletes, setAthletes] = useState([]);

  useEffect(() => {
    async function loadOpenedCompetitions() {
        const athletesPontuationResponse = await api.get(`athletes/absolute`);
        const athletesPontuation = athletesPontuationResponse.data;
        setAthletes(athletesPontuation);
    }

    loadOpenedCompetitions();
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps


  return (
    <Container>
      <TableDiv>
        <PrintHeader>
          <img src={logo}></img>
        </PrintHeader>
        <h1>Ranking Absoluto</h1>
        <Table>
            <thead>
                <tr>
                    <th>Atleta</th>
                    <th>Equipe</th>
                    <th>Pontuação Absoluta</th>
                </tr>
                
            </thead>
            <tbody>
            {
                athletes.map((athlete, index) => (
                    <tr>
                        <td>{athlete?.name}</td>
                        <td>{athlete?.team.name}</td>
                        <td>{athlete?.absolute_pontuation}</td>
                    </tr>
                ))
            }
            </tbody>
        </Table>
      </TableDiv>

    </Container>
  );
}

export default RankingAbsolute;
