import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";
import api from '../../../services/api';
import { Form, Input, Select } from '@rocketseat/unform';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
    format,
  } from 'date-fns';
import pt from 'date-fns/locale/pt';
import * as Yup from 'yup';

import history from '../../../services/history';
import { Container, FormDiv, TableDiv } from './styles';
import { Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function AthletesCompetition() {
  const teamProfile = useSelector(state => state.user.profile);
  let query = useQuery();
  

  const [athletes, setAthletes] = useState([]);
  const [stroke, setStrokes] = useState([]);
  const [stroke1, setStroke1] = useState();
  const [stroke2, setStroke2] = useState();
  const [enrolleds, setEnrolleds] = useState([]);
  const [groups, setGroups] = useState([]);
  const [relays, setRelays] = useState([]);

  useEffect(() => {
    async function loadAthletes() {
      const response = await api.get('athletes/team');
      const strokesResponse = await api.get('strokes');
      const enrolledResponse = await api.get(`enrolleds/team/${query.get('id')}`)
      const groupResponse = await api.get(`groups/${query.get('id')}`)
      const relaysResponse = await api.get('strokes/relays');

      const relaysData = relaysResponse.data;

      const enrolled = enrolledResponse.data;
      const group = groupResponse.data;

      const data = response.data;
      const strokes = strokesResponse.data;

      let strokesArray = []
      strokes.map((stroke) => {

        let strokeObj = {
          id: stroke.id,
          sex: stroke.sex,
          title: `${stroke.name} ${stroke.sex}`
        }
        if (stroke.enabled) strokesArray.push(strokeObj);
      })


      setRelays(relaysData);
      setEnrolleds(enrolled);
      setGroups(group);
      setAthletes(data);
      setStrokes(strokesArray);
    }

    loadAthletes();
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps

  const [modal, setModal] = useState(false);
  const [openedCompetitionId, setOpenedCompetitionId] = useState();
  const [profile, setProfile] = useState();
  const [updating, setUpdating] = useState(false);
  const [athleteProfile, setAthleteProfile] = useState();

  const toggle = () => setModal(!modal);

  const toggleId = (id) => {
    setModal(!modal);
    setOpenedCompetitionId(id);
  }

  function toggleUpdate(category) {
    setProfile(category);
    setUpdating(!updating);
  }

  async function signAthlete(athlete) {
    let strokes = []
    if (isNaN(parseInt(stroke1)) && isNaN(parseInt(stroke2))) strokes = null;
    else strokes = [parseInt(stroke1),parseInt(stroke2)];
    const currentAge = new Date().getFullYear() - athlete.birth_age;
    const categoryResponse = await api.get(`categories/${currentAge}`);

    const categorie = categoryResponse.data;

    const payload = {
      competition_id: parseInt(query.get('id')),
      athlete_id: athlete.id,
      categorie_id: categorie.id,
      strokes_list: strokes,
    };

    try{
      if (athlete.id != null) {
        await api.post('enrolleds', payload);
      }
      else {
        toast.error('Verifique se o atleta foi selecionado');
      }
    }
    catch(e) {
      toast.error('Verifique se o nado foi selecionado');
    }

    const enrolledResponse = await api.get(`enrolleds/team/${query.get('id')}`)

    const enrolled = enrolledResponse.data;

    setStroke1(NaN);
    setStroke2(NaN);
    setEnrolleds(enrolled);
  }

  async function signRelay(relay) {
    try{
      await api.post('groups', {
        competition_id: parseInt(query.get('id')),
        strokes_list: [relay.id],
      });
      toast.success('Atleta cadastrado com sucesso!');
    }
    catch(e) {
      toast.error('Verifique se o revezamento já não foi cadastrado');
    }

    const groupResponse = await api.get(`groups/${query.get('id')}`)

    const group = groupResponse.data;

    setGroups(group);
  }

  function handleStroke1(e) {
    setStroke1(e.target.value);
  }
  function handleStroke2(e) {
    setStroke2(e.target.value);
  }

  function updateAthlete(athlete) {
    setUpdating(!updating);
    setAthleteProfile(athlete);
  }

  async function confirmUpdate(athlete) {
    let strokes = []
    if (isNaN(parseInt(stroke1)) && isNaN(parseInt(stroke2))) strokes = null;
    else strokes = [parseInt(stroke1),parseInt(stroke2)];

    const payload = {
      athlete_id: athlete.id,
      strokes_list: strokes,
    }
    try{
      if (athlete.id != null) {
        await api.put(`enrolleds/update/${query.get('id')}`, payload);
        toast.success('Nados do atleta atualizados');
      }
      else {
        toast.error('Verifique se o atleta foi selecionado');
      }
    }
    catch(e) {
      toast.error('Erro ao atualizar o atleta');
    }

    const enrolledResponse = await api.get(`enrolleds/team/${query.get('id')}`)

    const enrolled = enrolledResponse.data;
    
    setUpdating(!updating);
    setStroke1(NaN);
    setStroke2(NaN);
    setEnrolleds(enrolled);
  }

  async function deleteAthlete(athlete) {
    try{
      if (athlete.id != null) {
        await api.delete(`enrolleds/delete/${query.get('id')}/${athlete.id}`);
        toast.success('Atleta excluido da competição');
      }
      else {
        toast.error('Erro ao deletar o atleta');
      }
    }
    catch(e) {
      toast.error('Erro ao deletar o atleta');
    }

    const enrolledResponse = await api.get(`enrolleds/team/${query.get('id')}`)

    const enrolled = enrolledResponse.data;
    
    setUpdating(!updating);
    setStroke1(NaN);
    setStroke2(NaN);
    setEnrolleds(enrolled);
  }


  async function deleteGroup(group_id, stroke_id) {

    try{
        await api.delete(`groups/delete/${query.get('id')}/${group_id}/${stroke_id}`);
        toast.success('Revezamento excluido da competição');


        const groupResponse = await api.get(`groups/${query.get('id')}`)
        const group = groupResponse.data;

        setGroups(group);
    }
    catch(e) {
      toast.error('Erro ao deletar o revezamento');
    }
    
  }

  return (
    <Container>
      <TableDiv>
        <h1>Selecione os Atletas</h1>
        <Table>
            <thead>
            <tr>
                <td>Nome</td>
                <td>Sexo</td>
                <td>Nado 1</td>
                <td>Nado 2</td>
                <td>Cadastrar</td>
            </tr>
            </thead>
            <tbody>
            {
            athletes.map((athlete,index) => (
              <tr key={index} id={athlete.id}>
                <td>{athlete.name}</td>
                <td>{athlete.sex}</td>
                {
                  (updating && athleteProfile.id == athlete.id) ? 
                  (
                    <>
                      <td><Select onChange={handleStroke1} name="stroke_id" placeholder="Nado 1" options={stroke.filter((stroke) => stroke.sex == athlete.sex)} /></td>
                      <td><Select onChange={handleStroke2} name="stroke_id" placeholder="Nado 2" options={stroke.filter((stroke) => stroke.sex == athlete.sex)} /></td>
                    </>
                  )
                  :
                  (
                    <>
                      <td>{enrolleds.find(enrolled => enrolled.athlete_id == athlete.id) ? stroke.find(stroke => stroke.id == enrolleds.find(enrolled => enrolled.athlete_id == athlete.id).strokes_list[0])?.title : (<Select onChange={handleStroke1} name="stroke_id" placeholder="Nado 1" options={stroke.filter(stroke => stroke.sex == athlete.sex)} />)}</td>
                      <td>{enrolleds.find(enrolled => enrolled.athlete_id == athlete.id) ? stroke.find(stroke => stroke.id == enrolleds.find(enrolled => enrolled.athlete_id == athlete.id).strokes_list[1])?.title : (<Select onChange={handleStroke2} name="stroke_id" placeholder="Nado 2" options={stroke.filter(stroke => stroke.sex == athlete.sex)} />)}</td>
                    </>
                  )
                }
                <td>
                  {
                  enrolleds.some(e => e.athlete_id === athlete.id) ? 
                    (
                      <>
                        {(updating && athleteProfile.id == athlete.id) ? (<button onClick={() => confirmUpdate(athlete)}>Confirmar Edição</button>):(<strong>Atleta Cadastrado</strong>)}
                        <button onClick={() => updateAthlete(athlete)}>{(updating && athleteProfile.id == athlete.id) ? 'Editando' : 'Editar Nados'}</button>
                        <button onClick={() => deleteAthlete(athlete)}>Excluir Atleta</button>
                      </>
                    )
                    : 
                    (
                      <button onClick={() => signAthlete(athlete)}>Cadastrar Atleta</button>
                    )
                  }
                </td>
              </tr>
            ))
            
          }
            </tbody>
        </Table>
        <h1>Selecione os Revezamentos</h1>
        <Table>
            <thead>
            <tr>
                <td>Revezamento</td>
                <td>Sexo</td>
                <td>Cadastrar</td>
            </tr>
            </thead>
            <tbody>
            {
            relays.map((relay,index) => (
              <>
                {
                  relay.enabled ? 
                  (
                    <tr key={index}>
                      <td>{relay.name}</td>
                      <td>{relay.sex}</td>
                      <td>
                        {
                        groups.some(e => e.team_id == teamProfile.id && e.strokes_list.includes(relay.id)) ? 
                          (
                            <>
                            <strong>Revezamento Cadastrado</strong>
                            <button onClick={() => deleteGroup(teamProfile.id, relay.id)}>Excluir Revezamento</button>
                            </>
                          )
                          : 
                          (
                            <button onClick={() => signRelay(relay)}>Cadastrar Revezamento</button>
                          )
                          }
                    </td>
                    </tr>
                  )
                  :
                  (
                    <></>
                  )
                }
              </>
            ))
            }
            </tbody>
        </Table>
      </TableDiv>

    </Container>
  );
}

export default AthletesCompetition;
