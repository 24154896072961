import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';

import { signInRequest } from '../../store/modules/auth/actions';

import { Wrapper, Content, HomeBanner, Footer } from './styles';

const schema = Yup.object().shape({
  login: Yup.string()
    .required('O login é obrigatório'),
  password: Yup.string().required('A senha é obrigatória'),
});

export default function SignUp() {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.loading);

  function handleSubmit({ login, password }) {
    dispatch(signInRequest(login, password));
  }

  return (
    <>
    <HomeBanner>
      <h1>FAÇA O LOGIN COM SEU USUÁRIO</h1>
    </HomeBanner>
    <Content>
        <Form schema={schema} onSubmit={handleSubmit}> 
          <span className='spanInput'>Usuário</span>
          <Input name="login" type="input" placeholder="Seu login" />
          <span className='spanInput'>Senha</span>
          <Input
            name="password"
            type="password"
            placeholder="Sua senha"
          />
  
          <button type="submit">Entrar</button>
        </Form>
      </Content>
      <Footer>
        <div>
        <span>A LIGA</span>
        <p>Respeitar a criança nas suas limitações técnicas. Introduzi-las gradativamente e prepará-las para as provas.
A graduação progressiva das provas e a motivação e atenuantes didáticos das dificuldades da criança.
Buscar na natação competitiva, uma ferramenta de inclusão do aluno no esporte de uma maneira saudável e construtiva.
Criar eventos, baseados em processos pedagógicos que atendessem o inicio positivo e educacional da modalidade.
Em fim, aperfeiçoar, unir e direcionar os profissionais responsáveis pelos processos pedagógicos que inserem nossas crianças no esporte em si.</p>
        </div>
      </Footer>
      </>
  );
}