import React, {useEffect, useState} from 'react';
import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";
import api from '../../../services/api';
import { Form, Input, Select } from '@rocketseat/unform';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
    format,
  } from 'date-fns';
import pt from 'date-fns/locale/pt';
import * as Yup from 'yup';

import logo from '../../../assets/logo.png'

import history from '../../../services/history';
import { Container, FormDiv, TableDiv, PrintHeader } from './styles';
import { Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import './styles.css'

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function RacesPrint() {
  let query = useQuery();

  const [races, setRaces] = useState([]);
  const [relaysRaces, setRelaysRaces] = useState([]);
  const [competition, setCompetition] = useState({});

  useEffect(() => {
    async function loadOpenedCompetitions() {
      const strokesResponse = await api.get('strokes');
      const relaysResponse = await api.get('strokes/relays');
      const strokes = strokesResponse.data;
      const relays = relaysResponse.data;

      const competitionReponse = await api.get(`competition/${query.get('id')}`)
      const competitionData = competitionReponse.data;

      let balizamento = [];
      let revezamentos = [];

      for (const stroke of strokes) {
        const response = await api.get(`races/${query.get('id')}/${stroke.id}`);
        const data = response.data;
        balizamento.push({
          stroke: `${stroke.name} ${stroke.sex}`,
          athletes: data,
        });
      }

      for (const relay of relays) {
        const response = await api.get(`relays/${query.get('id')}/${relay.id}`);
        const data = response.data;
        revezamentos.push({
          stroke: `${relay.name} ${relay.sex}`,
          teams: data,
        })
      }
      
      setRaces(balizamento);
      setRelaysRaces(revezamentos);
      setCompetition(competitionData);
    }

    loadOpenedCompetitions();
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps


  return (
    <Container>
    
      <TableDiv>
        <h1>Plaquetas</h1>
        {
        races.map((race,index) => (
            <div className='tablediv' key={index}>
            {race.athletes.map((athlete,index) => (
                <div  key={index}>
                <Table>
                    <img src={logo}></img>
                    <tbody>
                      <tr>
                          <th>Série</th>
                          <th>Raia</th>
                          <th>CPF</th>
                          <th>Tempo</th>
                          
                      </tr>
                      <tr>
                        <td>{athlete.race.order}</td>
                        <td>{athlete.race.lane}</td>
                        <td>{athlete.race.athlete.cpf}</td>
                        <td><input></input></td>
                      </tr>
                      <tr>
                        <td>{` `}</td>
                        <td>{` `}</td>
                        <td>{` `}</td>
                        <td>{` `}</td>
                      </tr>
                      <tr>
                        <th>Nome</th>
                        <th>Categoria</th>
                        <th>Equipe</th>
                        <th>Nado</th>
                      </tr>
                      <tr>
                        <td>{athlete.race.athlete?.name}</td>
                        <td>{athlete.race.category?.name}</td>
                        <td>{athlete.race.athlete?.team.name}</td>
                        <td>{race.stroke}</td>
                      </tr>
                    </tbody>
                </Table>
                </div>
            ))}
            </div>
        ))
        }
        {
        relaysRaces.map((relay,index) => (
            <div key={index}>
              {relay.teams.map((team,index) => (
                <div key={index}>
                <Table>
                  <tbody>
                  <tr>
                        <th>Série</th>
                        <th>Raia</th>
                        <th>Equipe</th>
                        <th>Tempo</th>
                        <th>Nado</th>
                    </tr>
                    <tr>
                      <td>{team.order}</td>
                      <td>{team.lane}</td>
                      <td>{team.team.name}</td>
                      <td><input></input></td>
                      <td>{relay.stroke}</td>
                    </tr>
                  </tbody>
                </Table>
                </div>
            ))}
            </div>
        ))
        }
      </TableDiv>

    </Container>
  );
}

export default RacesPrint;
