import React from 'react';

import { Container } from './styles';

function Navbar() {
  return (
    <Container>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <a className="navbar-brand" href="/">LPN</a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <a className="btn btn-outline-success my-2 my-sm-0" href="/login">Acessar</a>
        </button>
      
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <a className="btn btn-outline-success my-2 my-sm-0" href="/login">Acessar</a>
        </div>
      </nav>
    </Container>
  );
}

export default Navbar;