import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Content } from './styles';
import NavbarLogin from '../../../components/NavbarLogin';
import Sidebar from '../../../components/Sidebar';

export default function AuthLayout({ children }) {
  return (
    <>
      <Sidebar />
      <NavbarLogin />
      <Wrapper>
        <div className='children'>{children}</div>
      </Wrapper>
    </>
  );
}

AuthLayout.propTypes = {
  children: PropTypes.element.isRequired,
};