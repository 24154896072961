import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Content } from './styles';
import Navbar from '../../../components/Navbar';

export default function AuthLayout({ children }) {
  return (
    <>
      <Navbar />
      <Wrapper>
        <div className='children'>{children}</div>
      </Wrapper>
    </>
  );
}

AuthLayout.propTypes = {
  children: PropTypes.element.isRequired,
};