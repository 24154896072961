import styled from 'styled-components';
import bgIntro from '../../assets/bg-intro.png'



export const Wrapper = styled.div`
  height: 100vh;
  
  h2 {
    font-size: 36px;
    font-weight: bold;
    color: #333;
    text-transform: uppercase;
    padding-bottom: 15px;
    text-align: center;
  }
`;

export const Container = styled.div`
  width: 100%;
  padding: 0 5%;
`;

export const HomeBanner = styled.div`
  background-color: #fff;
  background-image: url(${bgIntro});
  background-blend-mode: luminosity;
  height: 400px;
  width: 100%;
  object-fit: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  h1 {
    padding: 0 10px;
    font-size: 56px;
    font-weight: bold;
    color: #fff;
    filter: drop-shadow(0 0 0.75rem black);
    span {
      color: #3498DB;
      font-size: 48px;
    }
  }
  
  span {
    padding: 0 10px;
    color: #fff;
    font-size: 36px;
    filter: drop-shadow(0 0 0.75rem black);
  }
`;

export const Etapas = styled.div`
  padding: 40px 0;
  
  div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    
    .card {
      max-width: 22rem;
    }

    .pontuation-link {
      color: #fff;
      display: inline;
      font-weight: bolder;
    }
    .pontuation-link:hover {
      color: #fff;
      text-decoration: underline;
    }
  }
  
`;

export const Separador = styled.img`
  height: 300px;
  width: 100%;
  object-fit: cover;
`;

export const Equipes = styled.div`
  padding: 40px 0;
  
  div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    
    .card {
      max-width: 25rem;
    }
  }
`;

export const Ranking = styled.div`
  padding: 40px 0;
`;

export const Footer = styled.div`
  padding: 40px 5%;
  background-color: #222;
  color: #fff;
  
  div {
    width: 100%;
    max-width: 50%;
    
    span {
      display: block;
      font-size: 28px;
      text-transform: uppercase;
      padding-bottom: 20px;
      font-weight: bold;
    }
    
    p {
      font-size: 16px;
      font-weight: lighter;
    }
  }
`;
