import React, {useEffect, useState} from 'react';
import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";
import api from '../../../services/api';
import { Form, Input, Select } from '@rocketseat/unform';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
    format,
  } from 'date-fns';
import pt from 'date-fns/locale/pt';
import * as Yup from 'yup';

import logo from '../../../assets/logo.png'

import history from '../../../services/history';
import { Container, FormDiv, TableDiv, PrintHeader } from './styles';
import { Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import './styles.css'

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function RacesPrint() {
  let query = useQuery();

  const [races, setRaces] = useState([]);
  const [relaysRaces, setRelaysRaces] = useState([]);
  const [competition, setCompetition] = useState({});

  useEffect(() => {
    async function loadOpenedCompetitions() {
      const strokesResponse = await api.get('strokes');
      const relaysResponse = await api.get('strokes/relays');
      const strokes = strokesResponse.data;
      const relays = relaysResponse.data;

      const competitionReponse = await api.get(`competition/${query.get('id')}`)
      const competitionData = competitionReponse.data;

      let balizamento = [];
      let revezamentos = [];

      for (const stroke of strokes) {
        const response = await api.get(`races/${query.get('id')}/${stroke.id}`);
        const data = response.data;
        balizamento.push({
          stroke: `${stroke.name} ${stroke.sex}`,
          orders: data[data.length-1]?.race.order,
          athletes: data,
        });
      }

      for (const relay of relays) {
        const response = await api.get(`relays/${query.get('id')}/${relay.id}`);
        const data = response.data;
        revezamentos.push({
          stroke: `${relay.name} ${relay.sex}`,
          teams: data,
        })
      }
      setRaces(balizamento);
      setRelaysRaces(revezamentos);
      setCompetition(competitionData);
    }

    loadOpenedCompetitions();
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps


  return (
    <Container>
      <TableDiv>
        <PrintHeader>
          <img src={logo}></img>
            <div>
            <h2>Cidade: {competition.city}</h2>
            <h2>Data: {format(new Date(moment(competition.date).format()), "d 'de' MMMM", { locale: pt })}</h2>
            <h2>Horario: {competition.time}</h2>
          </div>
        </PrintHeader>
        <h1>Balizamento</h1>
        {
        races.map((race, index) => (
          <div className='race' key={index}>
            {
              race.athletes.length > 0 ?
              (
                <>
                <h1>{race.stroke}</h1>
                <h2>Total de Atletas: {race.athletes.length}</h2>
                {Array(race.orders).fill().map((id, index) => (
                  <>
                    <h3>{`${race.stroke} - ${index+1}ª Série`}</h3>
                    <Table  key={index}>
                    <thead>
                    <tr>
                        <th>Série</th>
                        <th>Raia</th>
                        <th>Nome</th>
                        <th>Categoria</th>
                        <th>Equipe</th>
                        <th>Melhor tempo</th>
                    </tr>
                    </thead>
                    <tbody>
                    {race.athletes.map(athlete => (
                        <>
                          {athlete.race.order == index+1
                          ? 
                          (
                            <tr key={athlete.race.id}>
                              <td>{athlete.race.order}</td>
                              <td>{athlete.race.lane}</td>
                              <td>{athlete.race.athlete?.name}</td>
                              <td>{athlete.race.category?.name}</td>
                              <td>{athlete.race.athlete?.team.name}</td>
                              <td>{athlete.best_time?.time == null || athlete.best_time?.time == `99:99:99` ? `00:00:00` : athlete.best_time?.time}</td>
                            </tr>
                          )
                          :
                          (
                            <></>
                          )
                        }
                        </>
                    ))}
                    </tbody>
                  </Table>
                  </>
                ))}
                </>
              )
              :
              (
                <></>
              )
            }
          </div>
        ))
        }
        {
        relaysRaces.map((relay,index) => (
          <div className='race' key={index}>
            <h1>{relay.stroke}</h1>
            <h2>Total de Atletas: {relay.teams.length}</h2>
            <Table key={index}>
                <thead>
                <tr>
                    <th>Série</th>
                    <th>Raia</th>
                    <th>Nome da Equipe</th>
                </tr>
                </thead>
                <tbody>
                {relay.teams.map(team => (
                    <tr key={team.id}>
                      <td>{team.order}</td>
                      <td>{team.lane}</td>
                      <td>{team.team.name}</td>
                    </tr>
                ))}
                </tbody>
            </Table>
          </div>
        ))
        }
      </TableDiv>

    </Container>
  );
}

export default RacesPrint;
