import styled from 'styled-components';
import { darken } from 'polished';

import bgIntro from '../../assets/bg-intro.png'

export const Page = styled.div`
  margin-left: 200px;
`;

export const EmptyPage = styled.div`
  height: 100vh;
`;

export const HomeBanner = styled.div`
  background-image: url(${bgIntro});
  height: 200px;
  width: 100%;
  object-fit: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  h1 {
    padding: 0 10px;
    font-size: 48px;
    font-weight: bold;
    color: #fff;
    filter: drop-shadow(16px 16px 10px black);
    span {
      color: #3498DB;
      font-size: 48px;
    }
  }
`;

export const Content = styled.div`
  margin: 100px auto;
  width: 100%;
  max-width: 415px;
  text-align: center;
  background-color: #f1f1f1;
  padding: 80px 40px;
  border-radius: 4px;
  form {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    input {
      background: rgba(0, 0, 0, 0.1);
      border: 0;
      border-radius: 4px;
      height: 44px;
      padding: 0 15px;
      color: #000;
      margin: 0 0 10px;
      &::placeholder {
        color: #111;
      }
    }
    
    .spanInput {
      color: #333;
      align-self: flex-start;
      margin: 0 0 10px;
      font-weight: bold;
      font-size: 18px;
    }
    
    span {
      color: #fb6f91;
      align-self: flex-start;
      margin: 0 0 10px;
      font-weight: bold;
    }
    button {
      margin: 5px 0 0;
      height: 44px;
      background: #3b9eff;
      font-weight: bold;
      color: #fff;
      border: 0;
      border-radius: 4px;
      font-size: 16px;
      transition: background 0.2s;
      &:hover {
        background: ${darken(0.03, '#3b9eff')};
      }
    }
    a {
      color: #fff;
      margin-top: 15px;
      font-size: 16px;
      opacity: 0.8;
      &:hover {
        opacity: 1;
      }
    }
  }
`;

export const Footer = styled.div`
  padding: 40px 5%;
  background-color: #222;
  color: #fff;
  div {
    width: 100%;
    max-width: 50%;
    
    span {
      display: block;
      font-size: 28px;
      text-transform: uppercase;
      padding-bottom: 20px;
      font-weight: bold;
    }
    
    p {
      font-size: 16px;
      font-weight: lighter;
    }
  }
`