import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from '../../store/modules/auth/actions';

import { Container } from './styles';



function NavbarLogin() {
  const dispatch = useDispatch();

  function handleSignOut() {
    dispatch(signOut());
  }

  return (
    <Container>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <a className="navbar-brand" href="/">LPN</a>
        <a className="btn btn-danger my-2 my-sm-0 d-flex" onClick={handleSignOut}>Sair</a>
      </nav>
    </Container>
  );
}

export default NavbarLogin;