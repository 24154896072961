import React, {useEffect, useState} from 'react';
import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";
import api from '../../../services/api';
import { Form, Input, Select } from '@rocketseat/unform';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
    format,
  } from 'date-fns';
import pt from 'date-fns/locale/pt';
import * as Yup from 'yup';

import logo from '../../../assets/logo.png'

import history from '../../../services/history';
import { Container, FormDiv, TableDiv, PrintHeader } from './styles';
import { Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function ListAthletes() {
  let query = useQuery();

  const [athletes, setAthletes] = useState([]);
  const [strokes, setStrokes] = useState([]);

  useEffect(() => {
    async function loadAthletes() {
      const response = await api.get(`enrolleds/team/${query.get('id')}`)
      const data = response.data;

      const strokesResponse = await api.get('strokes');
      const strokes = strokesResponse.data;

      let strokesArray = []
      strokes.map((stroke) => {

        let strokeObj = {
          id: stroke.id,
          title: `${stroke.name} ${stroke.sex}`
        }
        strokesArray.push(strokeObj);
      })
      
      setAthletes(data);
      setStrokes(strokesArray);
    }

    loadAthletes();
  }, []);

  return (
    <Container>
      <TableDiv>
        <PrintHeader>
        </PrintHeader>
        <h1>Atletas Cadastrados Na Competição</h1>
          <div className='race'>
            <Table>
                <thead>
                  <tr>
                      <th>Nome</th>
                      <th>Sexo</th>
                      <th>Nado 1</th>
                      <th>Nado 2</th>
                  </tr>
                </thead>
                <tbody>
                    {
                    athletes.map((enrolled, index) => (
                        <tr key={index}>
                            <td>{enrolled.athlete.name}</td>
                            <td>{enrolled.athlete.sex}</td>
                            <td>{strokes.find(stroke => stroke.id == enrolled.strokes_list[0])?.title}</td>
                            <td>{strokes.find(stroke => stroke.id == enrolled.strokes_list[1])?.title}</td>
                        </tr>
                    ))
                    }
                </tbody>
            </Table>
          </div>
      </TableDiv>

    </Container>
  );
}

export default ListAthletes;
