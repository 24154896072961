import React from 'react';
import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";
import { useSelector } from 'react-redux';

import { Page, HomeBanner, Footer, EmptyPage } from './styles';
import api from '../../services/api';

import Categories from '../../components/Pages/Categories';
import Strokes from '../../components/Pages/Strokes';
import Teams from '../../components/Pages/Teams';
import Competitions from '../../components/Pages/Competitions';
import Athletes from '../../components/Pages/Athletes';
import OpenedCompetitions from '../../components/Pages/OpenedCompetitions';
import AthletesCompetition from '../../components/Pages/AthletesCompetition';
import Pontuation from '../../components/Pages/Pontuation';
import ClosedCompetitions from '../../components/Pages/ClosedCompetitions';
import PontuationCompetitions from '../../components/Pages/PontuationCompetitions';
import ListAthletes from '../../components/Pages/ListAthletes';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Dashboard() {
  
  let query = useQuery();

  const profile = useSelector(state => state.user.profile);
  return(
    <>
      <Page>
        <HomeBanner>
        <h1>
          {`Seja bem vindo ${profile.login}`}
        </h1>
        </HomeBanner>
        {
          profile.type == 'referee' ?
            query.get('name') == 'categories'?
            (
              <Categories />
            )
            :
            query.get('name') == 'strokes'?
            (
              <Strokes />
            )
            :
            query.get('name') == 'teams'?
            (
              <Teams />
            )
            :
            query.get('name') == 'competitions'?
            (
              <Competitions />
            )
            :
            query.get('name') == 'races'?
            (
              <ClosedCompetitions />
            )
            :
            query.get('name') == 'pontuation'?
            (
              <PontuationCompetitions />
            )
            :
            query.get('name') == 'pontuation-races'?
            (
              <Pontuation />
            )
            :
            (
              <EmptyPage />
            )
          :
          query.get('name') == 'athletes'?
          (
            <Athletes />
          )
          :
          query.get('name') == 'athletes-competition'?
          (
            <OpenedCompetitions />
          )
          :
          query.get('name') == 'sign-athletes'?
          (
            <AthletesCompetition />
          )
          :
          query.get('name') == 'list-athletes'?
          (
            <ListAthletes />
          )
          :
          (
            <EmptyPage />
          )
        }
        
        <Footer>
          <div>
          <span>A LIGA</span>
          <p>Respeitar a criança nas suas limitações técnicas. Introduzi-las gradativamente e prepará-las para as provas.
A graduação progressiva das provas e a motivação e atenuantes didáticos das dificuldades da criança.
Buscar na natação competitiva, uma ferramenta de inclusão do aluno no esporte de uma maneira saudável e construtiva.
Criar eventos, baseados em processos pedagógicos que atendessem o inicio positivo e educacional da modalidade.
Em fim, aperfeiçoar, unir e direcionar os profissionais responsáveis pelos processos pedagógicos que inserem nossas crianças no esporte em si.</p>
          </div>
        </Footer>
      </Page>
    </>
  );
}

export default Dashboard;