import React, {useEffect, useState} from 'react';
import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";
import api from '../../../services/api';
import { Form, Input, Select } from '@rocketseat/unform';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
    format,
  } from 'date-fns';
import pt from 'date-fns/locale/pt';
import * as Yup from 'yup';

import logo from '../../../assets/logo.png'

import history from '../../../services/history';
import { Container, FormDiv, TableDiv, PrintHeader } from './styles';
import { Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import './styles.css'

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function ReportsPrint() {
  let query = useQuery();


  const [informations, setInformation] = useState([]);
  const [teamInformations, setTeamInformation] = useState([]);
  const [competition, setCompetition] = useState({});

  useEffect(() => {
    async function loadOpenedCompetitions() {

      const enrolleds = await api.get(`enrolleds/${query.get('id')}`);
      const competitionReponse = await api.get(`competition/${query.get('id')}`)
      
      const competitionData = competitionReponse.data;
      const enrolledsData = enrolleds.data;

      setTeamInformation(enrolledsData.athletes_team)
      setInformation(enrolledsData);
      setCompetition(competitionData);
    }

    loadOpenedCompetitions();
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps


  return (
    <Container>
      <TableDiv>
        <PrintHeader>
          <img src={logo}></img>
            <div>
            <h2>Cidade: {competition.city}</h2>
            <h2>Data: {format(new Date(moment(competition.date).format()), "d 'de' MMMM", { locale: pt })}</h2>
            <h2>Horario: {competition.time}</h2>
          </div>
        </PrintHeader>
        {
        teamInformations.map((info, index) => (
            <>
            <h2>Equipe: {info.team}</h2>
            <Table>
              <thead>
                <tr>
                  <th>Atletas Masculino</th>
                  <th>Atletas Feminino</th>
                  <th>Total Atletas</th>
                  <th>Revezamento(s)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{info.athletes_male}</td>
                  <td>{info.athletes_female}</td>
                  <td>{info.athletes_team}</td>
                  <td>{info.relays_team}</td>
                </tr>
              </tbody>
            </Table>
            </>
        ))
        }
        <h2>Total de Inscritos</h2>
            <Table>
              <thead>
                <tr>
                  <th>Total Atletas</th>
                  <th>Revezamento(s)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{informations.athletes_number}</td>
                  <td>{informations.relays_number}</td>
                </tr>
              </tbody>
            </Table>
      </TableDiv>

    </Container>
  );
}

export default ReportsPrint;
