import React from 'react';
import { useSelector } from 'react-redux';

import { Container } from './styles';

function Sidebar() {
      
  const profile = useSelector(state => state.user.profile);

  return (
    <div className="w3-sidebar w3-bar-block w3-collapse w3-card w3-animate-left" style={{width:200, position:'fixed', display:'flex', flexWrap: 'wrap', paddingTop:56}} id="mySidebar">
        <h3 className="w3-bar-item">Menu</h3>
        {
          profile.type == 'referee' ?
            (
              <>
              <a href="/dashboard?name=categories" className="w3-bar-item w3-button">Categorias</a>
              <a href="/dashboard?name=strokes" className="w3-bar-item w3-button">Nados</a>
              <a href="/dashboard?name=teams" className="w3-bar-item w3-button">Equipes</a>
              <a href="/dashboard?name=competitions" className="w3-bar-item w3-button">Competições</a>
              <a href="/dashboard?name=races" className="w3-bar-item w3-button">Balizamentos</a>
              <a href="/dashboard?name=pontuation" className="w3-bar-item w3-button">Tempos e Pontuação</a>
              </>
            )
            :
            ( 
              <>
              <a href="/dashboard?name=athletes" className="w3-bar-item w3-button">Atletas</a>
              <a href="/dashboard?name=athletes-competition" className="w3-bar-item w3-button">Atletas por Competição</a>
              </>
            )
        }
    </div>
  );
}

export default Sidebar;