/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/style-prop-object */
import React, {useEffect, useState} from 'react';
import moment from 'moment';
import {
  format,
} from 'date-fns';
import pt from 'date-fns/locale/pt';
import api from '../../services/api';
import { Wrapper, Container, HomeBanner, Etapas, Separador, Equipes, Ranking, Footer } from './styles';

import bgSeparator from '../../assets/bg.jpg'

function Default() {
  const [competitions, setCompetitions] = useState([]);
  const [teams, setTeams] = useState([]);
  const [athletes, setAthletes] = useState([]);
  const [athletesCategory, setAthletesCategory] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    async function loadStrokes() {
      const response = await api.get('competitions');
      const data = response.data;

      const teamsPontuationResponse = await api.get(`teams`);
      const teamsPontuation = teamsPontuationResponse.data;

      const athletesPontuationResponse = await api.get(`athletes/absolute`);
      const athletesPontuation = athletesPontuationResponse.data;

      const athletesCategoryPontuationResponse = await api.get(`athletes/`);
      const athletesCategoryPontuation = athletesCategoryPontuationResponse.data;

      const categoriesResponse = await api.get(`categories/`);
      const categoriesDataMale = JSON.parse(JSON.stringify(categoriesResponse.data));
      const categoriesDataFemale = JSON.parse(JSON.stringify(categoriesResponse.data));

      let year =  new Date().getFullYear();
      let position = 1;
      let athletesArray = [];

      athletesPontuation.map((athlete,index) => {
        if (position <= 10) {
          athletesArray.push(athlete);
          position++;
        }
      })

      categoriesDataMale.map(categorie => {
        categorie.sex = "Masculino";
      });
      categoriesDataFemale.map(categorie => {
        categorie.sex = "Feminino";
      });

      const categoriesWithSex = categoriesDataMale.concat(categoriesDataFemale)

      let pontuationCategory = [];

      for (const category of categoriesWithSex) {
        const athletesCategory = await api.get(`athletes/category/${category.id}/${category.sex}`);
        pontuationCategory.push({
          category,
          athletes: athletesCategory.data,
        });
      }


      //const categoriesWithSex = categoriesMale.concat(categoriesFemale);
      setCompetitions(data);
      setTeams(teamsPontuation);
      setAthletes(athletesArray);
      setAthletesCategory(pontuationCategory);
      setCategories(categoriesWithSex);
    }

    loadStrokes();
  }, []);

  return (
    <Wrapper>
      <HomeBanner>
        <h1>SEJA MUITO BEM-VINDO À <span>LPN</span></h1>
        <span>Liga Paulista de Natação</span>
      </HomeBanner>
      <Container>
        <Etapas>
          <h2>Etapas de 2023</h2>
          <div>
          {
            competitions.map(competition => (
              <div className="card text-white bg-primary mb-3" key={competition.id}>
                <div className="card-header">Etapa {competition.city}</div>
                <div className="card-body">
                  <h5 className="card-title">Endereço: {competition.place}</h5>
                  <p className="card-text">Competição realizada no dia: {format(new Date(moment(competition.date).format()), "d 'de' MMMM", { locale: pt })} as: {competition.time}</p>
                  <a target="_blank" className="card-text pontuation-link" href={`/races?name=print-races&id=${competition.id}`}>Balizamento</a>
                  <a target="_blank" className="card-text pontuation-link" href={`/pontuationcompetition?name=print-pontuation&id=${competition.id}`}>Resultado por categoria</a>
                  <a target="_blank" className="card-text pontuation-link" href={`/pontuationabsolute?name=print-pontuation&id=${competition.id}`}>Resultado Absoluto/Revezamento</a>
                  
                  
                </div>
              </div>
            ))
          }
          </div>

          <h2><a href="/previouscompetitions">Etapas de Anos Anteriores</a></h2>
        </Etapas>
      </Container>
        <Separador src={`${bgSeparator}`} />
          
      <Container>
        <Equipes>
          <h2>Parceiros</h2>
          
          {/* <div>
            <div className="card">
              <img className="card-img-top" src={`${bgSeparator}`} alt="Card image cap" />
              <div className="card-body">
                <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
              </div>
            </div>
          </div> */}
        </Equipes>
      </Container>
      
      <Separador src={`${bgSeparator}`} />
        
      <Container>
        <Ranking>
          <h2>Ranking Circuito 2023</h2>
          <h3>Ranking Absoluto</h3>
          <table className="table table-striped">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Posição</th>
                <th scope="col">Nome</th>
                <th scope="col">Pontuação</th>
              </tr>
            </thead>
            <tbody>
              {
                athletes.map((athlete,index) => (
                  <tr key={index}>
                    <th scope="row">{index+1}</th>
                    <td>{athlete.name}</td>
                    <td>{athlete.absolute_pontuation}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          <h4 className='text-center'><a target="_blank" href={`/rankingabsolute?name=print-pontuation`}>Visualizar todo o ranking</a></h4>
          <h3>Ranking Equipes</h3>
          <table className="table table-striped">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Posição</th>
                <th scope="col">Equipe</th>
                <th scope="col">Pontuação</th>
              </tr>
            </thead>
            <tbody>
              {
                teams.map((team,index) => (
                  <tr key={index}>
                    <th scope="row">{index+1}</th>
                    <td>{team.name}</td>
                    <td>{team.pontuation}</td>
                  </tr>
                ))
              }
              
            </tbody>
          </table>
          <h3>Ranking Categorias</h3>
          <div style={{display: 'flex', flexWrap: 'wrap'}}>    
          {
            athletesCategory.map((pontuation,index) => (
              <div className="card categories-card" style={{width:'20rem', margin: 10}}>
                <div className="card-header">
                  {`${pontuation.category.name} - ${pontuation.category.sex}`}
                </div>
                <ul className="list-group list-group-flush">
                  {
                    pontuation.athletes.map((athlete, index) => (
                      <>
                        {index <=2 ? (<li className="list-group-item">{`#${index+1} - ${athlete.name}`}</li>) : (<></>)}
                      </>
                    ))
                  }
                </ul>
                <div className="card-header">
                <a target="_blank" href={`/rankingcategory?name=print-pontuation&id=${pontuation.category.id}&sex=${pontuation.category.sex}`}>Lista Completa</a>
                </div>
              </div>
            ))
          }
          </div>
        </Ranking>
      </Container>
      
      <Footer>
        <div>
        <span>A LIGA</span>
        <p>Respeitar a criança nas suas limitações técnicas. Introduzi-las gradativamente e prepará-las para as provas.
A graduação progressiva das provas e a motivação e atenuantes didáticos das dificuldades da criança.
Buscar na natação competitiva, uma ferramenta de inclusão do aluno no esporte de uma maneira saudável e construtiva.
Criar eventos, baseados em processos pedagógicos que atendessem o inicio positivo e educacional da modalidade.
Em fim, aperfeiçoar, unir e direcionar os profissionais responsáveis pelos processos pedagógicos que inserem nossas crianças no esporte em si.</p>
        </div>
      </Footer>
    </Wrapper>
  );
}

export default Default;
