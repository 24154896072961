import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  div {
    margin: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;


export const TableDiv = styled.div`
  table {
    
    table-layout: fixed;
    width: 100%;
    tr {
      td,th {
        padding: 10px 0;
        width: auto;
        word-wrap: break-word;

        button {
          background-color: #2da9e3;
          padding: 10px 0px;
          border-radius: 4px;
          border: none;
          color: #fff;
          font-weight: bold;
        }
      }
    }
  }
`;