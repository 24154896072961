import React, {useEffect, useState} from 'react';
import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";
import api from '../../../services/api';
import { Form, Input, Select } from '@rocketseat/unform';
import { toast } from 'react-toastify';
import InputMask from 'react-input-mask';

import * as Yup from 'yup';

import { Container, FormDiv, TableDiv } from './styles';
import { Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


const schema = Yup.object().shape({
  time: Yup.string().required('O tempo do atléta é obrigatório'),
});

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Pontuation() {
  let query = useQuery();
  const [strokeRaces, setStrokeRaces] = useState([]);
  const [relayRaces, setRelayRaces] = useState([]);
  
  useEffect(() => {
    async function loadCategories() {
      let strokesRaces = [];
      let relaysRaces = [];

      const strokesResponse = await api.get('strokes');
      const strokes = strokesResponse.data;

      const relaysResponse = await api.get('strokes/relays');
      const relays = relaysResponse.data;

      for (const stroke of strokes ) {
        const requestsStrokes = await api.get(`races/${query.get('id')}/${stroke.id}`);
        const requestsStrokesData = requestsStrokes.data;

        const StrokeRaceObj = {
          strokeName: `${stroke.name} ${stroke.sex}`,
          races: requestsStrokesData,
        }
        strokesRaces.push(StrokeRaceObj);
      }

      for (const relay of relays ) {
        const requestsStrokesRelay = await api.get(`relays/${query.get('id')}/${relay.id}`);
        const requestStrokesRelayData = requestsStrokesRelay.data;

        const StrokeRaceObj = {
          strokeName: `${relay.name} ${relay.sex}`,
          races: requestStrokesRelayData,
        }
        relaysRaces.push(StrokeRaceObj);
      }

      setStrokeRaces(strokesRaces);
      setRelayRaces(relaysRaces);
    }

    loadCategories();
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function handleSubmit(data) {
    if (updating) {
      try{

        if (profile.hasOwnProperty('team_id')) {
          await api.put(`relays/update/${profile.id}`, {
            time: data.time,
          });
        }
        else {
          await api.put(`races/${profile.id}`, {
            time: data.time,
          });
        }
        
        toast.success('Tempo cadastrado com sucesso');
        setProfile({});
        setUpdating(!updating);
      }
      catch(e) {
        toast.error('Tente novamente');
      }
    }

    let strokesRaces = [];
    let relaysRaces = [];

    const strokesResponse = await api.get('strokes');
    const strokes = strokesResponse.data;

    const relaysResponse = await api.get('strokes/relays');
    const relays = relaysResponse.data;

    for (const stroke of strokes ) {
      const requestsStrokes = await api.get(`races/${query.get('id')}/${stroke.id}`);
      const requestsStrokesData = requestsStrokes.data;

      const StrokeRaceObj = {
        strokeName: `${stroke.name} ${stroke.sex}`,
        races: requestsStrokesData,
      }
      strokesRaces.push(StrokeRaceObj);
    }

    for (const relay of relays ) {
      const requestsStrokesRelay = await api.get(`relays/${query.get('id')}/${relay.id}`);
      const requestStrokesRelayData = requestsStrokesRelay.data;

      const StrokeRaceObj = {
        strokeName: `${relay.name} ${relay.sex}`,
        races: requestStrokesRelayData,
      }
      relaysRaces.push(StrokeRaceObj);
    }

    setStrokeRaces(strokesRaces);
    setRelayRaces(relaysRaces);

  }

  const [profile, setProfile] = useState();
  const [updating, setUpdating] = useState(false);

  function toggleUpdate(category) {
    setProfile(category);
    setUpdating(!updating);
  }

  async function generatePontuation() {
    try {
      await api.put(`pontuations/${query.get('id')}`);
      toast.success('Pontuação Gerada com sucesso')
    }
    catch(e) {
      toast.error('Erro ao gerar a pontuação')
    }
  }

  return (
    <Container>
      <div>
        <h1>Tempo do Atleta</h1>
        <button className='pontuation-button' onClick={() => generatePontuation()}>Gerar Pontuação</button>
      </div>
      <TableDiv>
        {
          strokeRaces.map((stroke,index) => (
          <div className='stroke' key={index}>
            <h1>{stroke.strokeName}</h1>
            <Table>
              <thead>
              <tr>
                <th>Atleta</th>
                <th>Categoria</th>
                <th>Tempo</th>
                <th>Cadastrar Tempo</th>
              </tr>
              </thead>
              <tbody>
              {stroke.races.map((race,index) => (
                <tr key={index}>
                  <td>{race.race?.athlete?.name}</td>
                  <td>{race.race?.category?.name}</td>
                  <td>{race.race?.time == '99:99:99' ? 'DESCLASSIFICADO' : race.race?.time == '00:00:00' ? 'NÃO COMPARECEU' : race.race?.time}</td>
                  <td>
                    <button onClick={() => toggleUpdate(race.race)}>{updating && race.race?.id == profile.id ? 'Cancelar' : 'Editar'}</button>
                    {
                    updating && race.race?.id == profile.id ?
                    (
                      <Form schema={schema} initialData={profile} onSubmit={handleSubmit}>
                        <InputMask mask="99:99:99">
                          {() => <Input name="time" type="text" placeholder="00:00:00" required/>}
                        </InputMask>
                        <button type="submit">Salvar</button>
                      </Form>
                    )
                    :
                    (
                      <></>
                    )
                    }
                  </td>
                </tr>
              ))}
              </tbody>
            </Table>
          </div>
          ))
        }
        {
          relayRaces.map((stroke,index) => (
            <div className='stroke' key={index}>
              <h1>{stroke.strokeName}</h1>
              <Table>
                <thead>
                <tr>
                  <th>Equipe</th>
                  <th>Tempo</th>
                  <th>Cadastrar Tempo</th>
                </tr>
                </thead>
                <tbody>
                {stroke.races.map((race,index) => (
                  <tr key={index}>
                    <td>{race.team.name}</td>
                    <td>{race.time ? race.time : '00:00:00'}</td>
                    <td>
                      <button onClick={() => toggleUpdate(race)}>{updating && race.id == profile.id ? 'Cancelar' : 'Editar'}</button>
                      {
                      updating && race.id == profile.id ?
                      (
                        <Form schema={schema} initialData={profile} onSubmit={handleSubmit}>
                          <InputMask mask="99:99:99">
                            {() => <Input name="time" type="text" placeholder="00:00:00" required/>}
                          </InputMask>
                          <button type="submit">Salvar</button>
                        </Form>
                      )
                      :
                      (
                        <></>
                      )
                      }
                    </td>
                  </tr>
                ))}
                </tbody>
              </Table>
            </div>
          ))
        }
      </TableDiv>

    </Container>
  );
}

export default Pontuation;
