import React, {useEffect, useState} from 'react';
import api from '../../../services/api';
import { Form, Input, Select } from '@rocketseat/unform';
import { toast } from 'react-toastify';

import * as Yup from 'yup';

import { Container, FormDiv, TableDiv } from './styles';
import { Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


const schema = Yup.object().shape({
  name: Yup.string().required('O nome da categoria é obrigatório'),
  sex: Yup.string().required('O sexo é obrigatório'),
});

const schemaOrder = Yup.object().shape({
  order: Yup.number(),
});

function Strokes() {
  const [strokes, setStrokes] = useState([]);

  useEffect(() => {
    async function loadStrokes() {
      const response = await api.get('strokes');
      const responseRelays = await api.get('strokes/relays');

      const data = response.data;
      const relaysData = responseRelays.data;

      setStrokes(data.concat(relaysData));
    }

    loadStrokes();
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function handleSubmit(data) {
    if (!updating) {
      try{
        await api.post('strokes', data);
      }
      catch(e) {
        toast.error('Verifique se o nado já existe');
      }
    }
    else {
      try{
        await api.put(`strokes/${profile.id}`, {
          name: data.name,
          sex: data.value,
        });
        toast.success('Nado alterado com sucesso');
        setProfile({});
        setUpdating(!updating);
      }
      catch(e) {
        toast.error('Verifique se o nado já existe');
      }
    }


    const response = await api.get('strokes');
    const responseRelays = await api.get('strokes/relays');

    setStrokes(response.data.concat(responseRelays.data));
  }

  const [modal, setModal] = useState(false);
  const [strokeId, setStrokeId] = useState();
  const [profile, setProfile] = useState();
  const [updating, setUpdating] = useState(false);
  const [updatingOrder, setUpdatingOrder] = useState(false);

  const toggle = () => setModal(!modal);

  const toggleId = (id) => {
    setModal(!modal);
    setStrokeId(id);
  }

  async function deleteProduct() {
    try {
      await api.delete(`strokes/${strokeId}`);
      toast.success('Nado deletado com sucesso');
    }
    catch(e) {
      toast.error('Erro ao deletar o nado');
    }

    const response = await api.get('strokes');

    setStrokes(response.data);
    setModal(!modal);
  }

  async function toggleUpdate(stroke) {
    try{
      await api.put(`strokes/${stroke.id}`);
      toast.success('Nado atualizado com sucesso');
    }
    catch(e) {
      toast.error('Erro ao atualizar o nado');
    }

    const response = await api.get('strokes');
    const responseRelays = await api.get('strokes/relays');

    setStrokes(response.data.concat(responseRelays.data));
  }

  function toggleUpdateOrder(stroke) {
    setProfile(stroke);
    setUpdatingOrder(!updatingOrder);
  }

  async function handleOrder(data) {
    try{
      await api.put(`strokes/order/${profile.id}`, {
        order: data.order,
      });
      toast.success('Nado alterado com sucesso');
      setProfile({});
      setUpdatingOrder(!updatingOrder);
    }
    catch(e) {
      toast.error('Verifique se o nado já existe');
    }

    const response = await api.get('strokes');
    const responseRelays = await api.get('strokes/relays');

    setStrokes(response.data.concat(responseRelays.data));
  }

  return (
    <Container>
      <FormDiv>
      <h1>Nado</h1>
      {
        updatingOrder ? 
        (
          <Form schema={schemaOrder} initialData={profile} onSubmit={handleOrder}>
            <Input name="order" type="text" placeholder="Ordem do nado" />
            <button type="submit">Salvar</button>
          </Form>
        )
        :
        (
          <Form schema={schema} initialData={profile} onSubmit={handleSubmit}>
            <Input name="name" type="text" placeholder="Nome do nado" />
            <Select name="sex" placeholder="Sexo" options={[{id: "Feminino", title: "Feminino"},{id: "Masculino", title: "Masculino"}]} />
            <button type="submit">Salvar</button>
          </Form>
        )
      }
      </FormDiv>
      <h1>Nados habilitados</h1>
      <TableDiv>
        <Table>
          <thead>
            <tr>
              <th>Nado</th>
              <th>Sexo</th>
              <th>Habilitar</th>
              <th>Ordem</th>
            </tr>
          </thead>
          <tbody>
          {
            strokes.map(stroke => (
              <>
                {
                  stroke.enabled ?
                  (
                    <tr key={stroke.id}>
                      <td>{stroke.name}</td>
                      <td>{stroke.sex}</td>
                      <td>
                        <button onClick={() => toggleUpdate(stroke)}>{stroke.enabled ? 'Desabilitar' : 'Habilitar'}</button>
                      </td>
                      <td>
                        <h4>{stroke.order}</h4>
                        <button onClick={() => toggleUpdateOrder(stroke)}>{updatingOrder && profile.id == stroke.id ? 'Cancelar' : 'Editar Ordem'}</button>
                      </td>
                    </tr>
                  )
                  :
                  (
                    <></>
                  )
                }
              </>
            ))
          }
          </tbody>
        </Table>
      </TableDiv>

      <h1>Nados desabilitados</h1>
      <TableDiv>
        <Table>
          <thead>
            <tr>
              <th>Nado</th>
              <th>Sexo</th>
              <th>Habilitar</th>
              <th>Ordem</th>
            </tr>
          </thead>
          <tbody>
          {
            strokes.map(stroke => (
              <>
                {
                  !stroke.enabled ?
                  (
                    <tr key={stroke.id}>
                      <td>{stroke.name}</td>
                      <td>{stroke.sex}</td>
                      <td>
                        <button onClick={() => toggleUpdate(stroke)}>{stroke.enabled ? 'Desabilitar' : 'Habilitar'}</button>
                      </td>
                      <td>
                        <h4>{stroke.order}</h4>
                        <button onClick={() => toggleUpdateOrder(stroke)}>{updatingOrder && profile.id == stroke.id ? 'Cancelar' : 'Editar Ordem'}</button>
                      </td>
                    </tr>
                  )
                  :
                  (
                    <></>
                  )
                }
              </>
            ))
          }
          </tbody>
        </Table>
      </TableDiv>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Deletar Categoria</ModalHeader>
        <ModalBody>
          Deseja Realmente deletar a categoria ?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => deleteProduct()}>Deletar</Button>{' '}
          <Button color="secondary" onClick={toggle}>Cancelar</Button>
        </ModalFooter>
      </Modal>

    </Container>
  );
}

export default Strokes;
