import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import api from '../../../services/api';
import { Form, Input, Select } from '@rocketseat/unform';
import { toast } from 'react-toastify';

import * as Yup from 'yup';

import { Container, FormDiv, TableDiv } from './styles';
import { Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


const schema = Yup.object().shape({
  name: Yup.string().required('O nome do atléta é obrigatório'),
  cpf: Yup.string().required('O CPF do atléta é obrigatório'),
  city: Yup.string().required('A cidade do atléta é obrigatório'),
  sex: Yup.string().required('O sexo do atléta é obrigatório'),
  birth_age: Yup.number().typeError('Digite um número valido').required('O ano de nascimento do atléta é obrigatório'),
});

function Athletes() {
    //const profile = useSelector(state => state.user.profile);

  const [athletes, setAthletes] = useState([]);

  useEffect(() => {
    async function loadAthletes() {
      const response = await api.get(`athletes/team`);

      const data = response.data;

      setAthletes(data);
    }

    loadAthletes();
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function handleSubmit(data) {
    if (!updating) {
      try{
        await api.post('athletes', data);
      }
      catch(e) {
        toast.error('Verifique se o atleta já existe');
      }
    }
    else {
      try{
        await api.put(`athletes/${profile.id}`, {
          name: data.name,
          cpf: data.cpf,
          city: data.city,
          sex: data.sex,
          birth_age: data.birth_age,
        });
        toast.success('Atleta alterado com sucesso');
        setProfile({});
        setUpdating(!updating);
      }
      catch(e) {
        toast.error('Verifique se o atleta já existe');
      }
    }


    const response = await api.get('athletes/team');

    setAthletes(response.data);

  }


  const [modal, setModal] = useState(false);
  const [athleteId, setAthleteId] = useState();
  const [profile, setProfile] = useState();
  const [updating, setUpdating] = useState(false);

  const toggle = () => setModal(!modal);

  const toggleId = (id) => {
    setModal(!modal);
    setAthleteId(id);
  }

  async function deleteAthlete() {
    try {
      await api.delete(`athletes/${athleteId}`);
      toast.success('Atleta deletado com sucesso');
    }
    catch(e) {
      toast.error('Erro ao deletar o atleta');
    }

    const response = await api.get('athletes/team');

    setAthletes(response.data);
    setModal(!modal);
  }

  function toggleUpdate(category) {
    setProfile(category);
    setUpdating(!updating);
  }

  return (
    <Container>
      <FormDiv>
      <h1>Atleta</h1>
      <Form schema={schema} initialData={profile} onSubmit={handleSubmit}>
        <Input name="name" type="text" placeholder="Nome do Atleta" />
        <Input name="cpf" type="text" placeholder="CPF do atleta" />
        <Input name="city" type="text" placeholder="Cidade do atleta" />
        <Select name="sex" placeholder="Sexo" options={[{id: "Feminino", title: "Feminino"},{id: "Masculino", title: "Masculino"}]} />
        <Input name="birth_age" type="number" min={0} placeholder="Ano de nascimento do atleta" />
        <button type="submit">Salvar</button>
      </Form>
      </FormDiv>
      <TableDiv>
        <Table>
          <thead>
          <tr>
            <th>Nome</th>
            <th>CPF</th>
            <th>Cidade</th>
            <th>Sexo</th>
            <th>Nascimento</th>
            <th>Ações</th>
          </tr>
          </thead>
          <tbody>
          {
            athletes.map((athlete,index) => (
              <tr key={index}>
                <td>{athlete.name}</td>
                <td>{athlete.cpf}</td>
                <td>{athlete.city}</td>
                <td>{athlete.sex}</td>
                <td>{athlete.birth_age}</td>
                <td>
                    <button onClick={() => toggleUpdate(athlete)}>{updating && athlete.id == profile.id ? 'Cancelar' : 'Editar'}</button>
                    {athlete.pontuation == 0 || athlete.absolute_pontuation == 0 ? (<button onClick={() => toggleId(athlete.id)}>Deletar</button>) : (<></>)}
                </td>
              </tr>
            ))
          }
          </tbody>
        </Table>
      </TableDiv>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Deletar Atleta</ModalHeader>
        <ModalBody>
          Deseja Realmente deletar o atleta ?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => deleteAthlete()}>Deletar</Button>{' '}
          <Button color="secondary" onClick={toggle}>Cancelar</Button>
        </ModalFooter>
      </Modal>

    </Container>
  );
}

export default Athletes;